import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, take } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { RequestResonse } from "@shared/models/requestResponse";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  apiUrl: string = `${environment.apiBase}/notifications`;
  unSeenCount = new BehaviorSubject(0);
  constructor(private http: HttpClient) {}

  getNotifications(page: number, limit: number) {
    return this.http
      .get<RequestResonse>(`${this.apiUrl}/?page=${page}&limit=${limit}`)
      .pipe(map((res) => res?.data));
  }

  getUnreadNotificationsCount() {
    return this.http
      .get<any>(`${this.apiUrl}/unreadCount`)
      .pipe(map((res) => res?.data))
      .pipe(take(1))
      .subscribe((count) => {
        this.unSeenCount.next(count);
      });
  }

  markNotificationsAsRead(notificationsIds: number[]) {
    return this.http
      .patch<RequestResonse>(`${this.apiUrl}/markAsRead`, { notificationsIds })
      .pipe(map((res) => res?.data));
  }

  getAllUsers(page: number, limit: number) {
    return this.http
      .get<RequestResonse>(
        `${environment.apiBase}/search?page=${page}&limit=${limit}`
      )
      .pipe(map((res) => res?.data));
  }

  sendNotification(requestBody: any) {
    return this.http.post<any>(`${this.apiUrl}/send`, requestBody);
  }
}
