import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from "@nebular/theme";

import { LayoutService } from "../../../@core/utils";
import { map, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AuthService } from "app/auth/auth.service";
import { Router } from "@angular/router";
import { User } from "../../../pages/users/models/user";
import { NotificationService } from "app/pages/notificatios/services/notification.service";

@Component({
  selector: "ngx-header",
  styleUrls: ["./header.component.scss"],
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  user: User;
  profile = localStorage.getItem("profile");
  name = localStorage.getItem("name");
  unSeenNotificationsCount: number = 0;

  currentTheme = "default";
  userMenu = [{ title: "Profile" }, { title: "Log out" }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));

    this.notificationService.unSeenCount.subscribe(
      (count) => (this.unSeenNotificationsCount = count)
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, "menu-sidebar");
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    this.router.navigateByUrl("/pages");
    return false;
  }

  onLogout(): void {
    this.authService.logout();
  }
}
