import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbThemeService } from "@nebular/theme";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
  selector: "ngx-theme-btn",
  templateUrl: "./theme-btn.component.html",
  styleUrls: ["./theme-btn.component.scss"],
})
export class ThemeBtnComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  currentTheme = "default";

  constructor(private themeService: NbThemeService) {}

  ngOnInit(): void {
    this.currentTheme = this.themeService.currentTheme;
    this.themeService
      .onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$)
      )
      .subscribe((themeName) => (this.currentTheme = themeName));
  }

  changeTheme(mode: boolean) {
    if (mode) {
      this.themeService.changeTheme("dark");
      localStorage.setItem("theme", "dark");
    } else {
      this.themeService.changeTheme("default");
      localStorage.setItem("theme", "default");
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
