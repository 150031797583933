import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { jwtDecode } from "jwt-decode";
import { Router } from "@angular/router";
import { AuthService } from "app/auth/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  constructor(private router: Router, private authService: AuthService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const lsToken: string | null = localStorage.getItem("token");
    const ssToken: string | null = sessionStorage.getItem("token");
    const token: string | null = lsToken ? lsToken : ssToken;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (token) {
      request = request.clone({
        headers: new HttpHeaders({
          Authorization: token,
          timezone: timezone,
        }),
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event),
      catchError((error: HttpErrorResponse) => {
        const refreshToken = localStorage.getItem("refreshToken");
        const decodedRefreshToken = jwtDecode(refreshToken);
        if (error.status === 401 || error.status === 409) {
          if (decodedRefreshToken.exp < Date.now() / 1000) {
            this.authService.logout(true);
          } else {
            this.authService.refreshToken(refreshToken);
          }
        }
        return throwError(error);
      })
    );
  }
}
