import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { map, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  apiUrl: string = `${environment.apiBase}/auth`;

  constructor(private http: HttpClient, private router: Router) {}

  login(loginData: any) {
    return this.http.post<any>(`${this.apiUrl}/login`, loginData).pipe(
      map((response) => {
        return response.data;
      }),
      tap((user) => {
        if (user) {
          localStorage.setItem("token", user.token);
          localStorage.setItem("refreshToken", user.refreshToken);
          localStorage.setItem("profile", user?.avatar?.path);
          localStorage.setItem("name", user.firstName);
        }
      })
    );
  }
  refreshToken(oldRefreshToken: string) {
    const headers = new HttpHeaders({
      Authorization: oldRefreshToken,
    });
    return this.http
      .post<any>(`${this.apiUrl}/refreshToken`, null, { headers })
      .pipe(
        map((response) => {
          return response.data;
        }),
        tap((user) => {
          if (user) {
            localStorage.setItem("token", user.token);
            localStorage.setItem("refreshToken", user.refreshToken);
          }
        })
      );
  }

  tokenIsExpired(token: string): boolean {
    if (token) {
      const tokenPayload = this.parseJwt(token);
      const tokenExpiredTimeInSeconds = tokenPayload?.exp;
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      return currentTimeInSeconds >= tokenExpiredTimeInSeconds;
    }
    return false;
  }

  logout(tokenExpiredFlag?: boolean) {
    localStorage.removeItem("token");
    localStorage.removeItem("profile");
    localStorage.removeItem("name");
    tokenExpiredFlag
      ? this.router.navigate(["auth", "login"], {
          state: { tokenExpired: tokenExpiredFlag },
        })
      : this.router.navigate(["auth", "login"]);
  }

  private parseJwt(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c: string) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}
