import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthChildrenGuard implements CanActivateChild {
  constructor(private authService: AuthService) {}
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let token = localStorage.getItem("token");
    if (!this.authService.tokenIsExpired(token)) {
      return true;
    }
    this.authService.logout();
    return false;
  }
}
