<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <img loading="lazy" src="assets/icons/menu.svg" />
    </a>

    <a class="logo" (click)="navigateHome()">
      <img loading="lazy" class="logo-img" src="{{
          currentTheme === 'dark'
            ? 'assets/logo/logo2.ico'
            : 'assets/logo/logo.png'
        }}" />
    </a>
  </div>

  <ngx-theme-btn class="control-item"></ngx-theme-btn>
</div>

<div class="header-container">
  <div class="notification" [routerLink]="['/pages/notifications']">
    <span class="notification-icon">
      <img [src]="'assets/icons/notification-' + currentTheme + '.svg'" alt="notification" />
    </span>
    <span class="notification-count">{{
      unSeenNotificationsCount > 0 ? unSeenNotificationsCount : ""
      }}</span>
  </div>
  <nb-actions size="small">
    <nb-action class="control-item">
      <div class="profile">
        <div class="profile-img">
          <img loading="lazy" src="{{
              profile !== 'undefined' ? profile : 'assets/images/user.png'
            }}" [alt]="name" />
        </div>
        <div class="profile-name">{{ name }}</div>
      </div>
    </nb-action></nb-actions>

  <nb-actions size="small">
    <nb-action class="control-item">
      <button name="logOut" nbButton status="basic" (click)="onLogout()">
        <mat-icon>logout</mat-icon> Logout
      </button>
    </nb-action>
  </nb-actions>
</div>