import { Component } from "@angular/core";
import { NbThemeService } from "@nebular/theme";

@Component({
  selector: "ngx-app",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent {
  constructor(private themeService: NbThemeService) {
    const theme = localStorage.getItem("theme");
    this.themeService.changeTheme(theme || "default");
  }
}
